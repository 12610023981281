import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Row,
    Col
} from "reactstrap"
import { get } from '../../helpers/helper_api';
export default function Footer(props) {

    const [city, setCity] = useState([]);
    const [cats, setCats] = useState(undefined);
    const [logo, setLogo] = useState(undefined)
    // console.log("headerData", city)

    useEffect(() => {
        if (props?.headerData?.city)
            setCity(props?.headerData?.city);
    }, [props]);

    const parseTitle = (title) => {
        let str = title.toLowerCase();
        str = str.split(" ").join("-");
        return str;
    }
    useEffect(() => {
        if (localStorage.getItem('footerLogo')) {
            setLogo(localStorage.getItem('footerLogo'))
        }
    }, [localStorage.getItem('footerLogo')])

    useEffect(() => {
        getCats()
    }, [])

    const getCats = () => {
        get(`category/list?all=${true}`)
            .then(res => {

                if (res?.statusCode == 200) {
                    setCats(res?.data);
                }
            })
            .catch(err => {

                toast.error("Something Went Wrong!");
            })
    }

    const goTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <footer className='padding-left-right'>
            <div className='footer-section'>
                <Row >
                    <Col lg={5}>
                        <div className='footer-content-section'>
                            <div className='footer-logo'>
                                <img src={logo} alt='w-logo' />
                            </div>
                            <p>Established and launched in 2019, Onward Workspaces is a Delhi-based co-working space that strives to nurture innovative startups by providing them with the required support. We aim to design & develop offices that change the way people feel about coming to work, foster a culture of sharing and exchange, and bring the best out of every individual.</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div className='footer-link'>
                            <h4>Loaction</h4>
                            <ul>
                                {city ? city?.map((item) => (
                                    <li><Link to={`/${parseTitle(item?.path)}`}>{item?.title}</Link></li>
                                )) : null}
                            </ul>
                        </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                        <div className='footer-link'>
                            <h4>Category</h4>
                            <ul>
                                {cats?.map((item, index) => (
                                    <li key={index}><Link to={`/category/${parseTitle(item?.title)}`} className='text-decoration-none'>{item?.title}</Link></li>
                                ))}
                            </ul>
                        </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                        <div className='footer-link'>
                            <h4>Onward</h4>
                            <ul>
                                <li><Link to='/' onClick={goTop}>Home</Link></li>
                                <li><Link to='/about' onClick={goTop}>About Us</Link></li>
                                <li><a href='/#workspace'>Workspace</a></li>
                                <li><Link to='/contact' onClick={goTop}>Reach Us</Link></li>
                                <li>
                                    <Link to="/partnerships" onClick={goTop}>Partnerships</Link>
                                </li>
                                <li>
                                    <Link to="/partner" onClick={goTop}>Partner</Link>
                                </li>
                                <li>
                                    <Link to="/careers" onClick={goTop}>Careers</Link>
                                </li>
                                <li>
                                    <Link to="/Referral" onClick={goTop}>Referral</Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className='footer-bottom'>
                <p>Copyright © Onward Workspace {new Date().getFullYear()}, All rights reserved.</p>
                <ul>
                    {/* <li>
                        <a href='#'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Social icon">
                                    <path id="Vector" d="M7.55016 21.7497C16.6045 21.7497 21.5583 14.2465 21.5583 7.74162C21.5583 7.53068 21.5536 7.31505 21.5442 7.10412C22.5079 6.40722 23.3395 5.54401 24 4.55505C23.1025 4.95436 22.1496 5.21514 21.1739 5.32849C22.2013 4.71266 22.9705 3.74523 23.3391 2.60552C22.3726 3.17831 21.3156 3.58237 20.2134 3.80037C19.4708 3.01132 18.489 2.48887 17.4197 2.31381C16.3504 2.13874 15.2532 2.32081 14.2977 2.83185C13.3423 3.3429 12.5818 4.15446 12.1338 5.14107C11.6859 6.12767 11.5754 7.23437 11.8195 8.29005C9.86249 8.19185 7.94794 7.68346 6.19998 6.79785C4.45203 5.91225 2.90969 4.66919 1.67297 3.14927C1.0444 4.233 0.852057 5.5154 1.13503 6.73585C1.418 7.95629 2.15506 9.0232 3.19641 9.71974C2.41463 9.69492 1.64998 9.48444 0.965625 9.10568V9.16662C0.964925 10.3039 1.3581 11.4063 2.07831 12.2865C2.79852 13.1667 3.80132 13.7703 4.91625 13.9947C4.19206 14.1929 3.43198 14.2218 2.69484 14.0791C3.00945 15.0572 3.62157 15.9126 4.44577 16.5261C5.26997 17.1395 6.26512 17.4804 7.29234 17.501C5.54842 18.8709 3.39417 19.6139 1.17656 19.6104C0.783287 19.6098 0.390399 19.5857 0 19.5382C2.25286 20.9835 4.87353 21.7511 7.55016 21.7497Z" fill="white" />
                                </g>
                            </svg>
                        </a>
                    </li> */}
                    <li>
                        <a href='https://www.linkedin.com/company/onwardworkspaces/' target='_blank'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Social icon" clip-path="url(#clip0_370_761)">
                                    <g id="Group">
                                        <path id="Vector" d="M22.2234 -0.000488281H1.77187C0.792187 -0.000488281 0 0.772949 0 1.7292V22.2651C0 23.2214 0.792187 23.9995 1.77187 23.9995H22.2234C23.2031 23.9995 24 23.2214 24 22.2698V1.7292C24 0.772949 23.2031 -0.000488281 22.2234 -0.000488281ZM7.12031 20.4511H3.55781V8.99482H7.12031V20.4511ZM5.33906 7.43389C4.19531 7.43389 3.27188 6.51045 3.27188 5.37139C3.27188 4.23232 4.19531 3.30889 5.33906 3.30889C6.47813 3.30889 7.40156 4.23232 7.40156 5.37139C7.40156 6.50576 6.47813 7.43389 5.33906 7.43389ZM20.4516 20.4511H16.8937V14.8823C16.8937 13.5558 16.8703 11.8448 15.0422 11.8448C13.1906 11.8448 12.9094 13.2933 12.9094 14.7886V20.4511H9.35625V8.99482H12.7687V10.5604H12.8156C13.2891 9.66045 14.4516 8.70889 16.1813 8.70889C19.7859 8.70889 20.4516 11.0808 20.4516 14.1651V20.4511Z" fill="white" />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_370_761">
                                        <rect width="24" height="24" fill="white" transform="translate(0 -0.000488281)" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </a>
                    </li>
                    <li>
                        <a href='https://www.instagram.com/onwardworkspaces/' target='_blank'>
                           <img src={require('../../assets/images/instagram.png')} />
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}
